import React from "react";
import classNames from "classnames";
import styles from "./Textarea.module.scss";

const Textarea = ({ className, minHeight, field, form, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <textarea
        {...field}
        {...props}
        className={classNames(styles.textareaContainer, className, {
          [styles.errorBorder]:
            form?.errors[field.name] && form.touched[field.name],
        })}
        style={{ minHeight: minHeight }}
      />
      {form?.errors[field.name] && form.touched[field.name] && (
        <div className={styles.error}>{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default Textarea;
