import React, { useState } from "react";
import styles from "./Findings.module.scss";
import Table from "../../components/Table/Table";
import { useLocation, useParams } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import PageLoader from "../../components/Loader/PageLoader";
import classNames from "classnames";
import Input from "../../components/Input/Input";
import SearchIcon from "../../icons/search.svg";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import { formatDate, handleExport } from "../../utils/helpers";
import { showModal } from "../../redux/modalStore";
import { useDispatch, useSelector } from "react-redux";
import FindingsModal from "../../modals/FindingsModal";
import { FINDINGS_LOADER, FINDINGS_MODAL_LOADER } from "../../constants/loader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Separator from "../../components/Separator/Separator";
import { setLinkedData } from "../../redux/organizationStore";
import Button from "../../components/Button/Button";
import DateRangeFilter from "../../components/DateRangeFilter/DateFIlterRange";
import useFetchOrganizationData from "../../actions/useFetchOrganizationData";

const Findings = () => {
  const [selectedFindingData, setSelectedFindingData] = useState(null);
  const [selectedFindings, setSelectedFindings] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const { orgId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const sessionToken = localStorage.getItem("session_token");

  useFetchOrganizationData();

  const loadingContexts = useSelector((state) => state.loader.loadingContexts);
  const modules = useSelector((state) => state.user.modules[orgId]);
  const { meModeActive } = useSelector((state) => state.filters);
  const { risksData, findingsData, data } = useSelector(
    (state) => state.organization,
  );
  const currentUser = useSelector((state) => state.user.email);

  const isFindingsModalLoading = loadingContexts[FINDINGS_MODAL_LOADER];
  const isFindingsDataLoading = loadingContexts[FINDINGS_LOADER];

  // Extracting path from URL
  const lastIndex = location.pathname.lastIndexOf("/");
  const modifiedPath = location.pathname.substring(lastIndex);

  // Find the module object corresponding to the current path
  const currentModule = modules?.find((mod) => mod.path === modifiedPath);

  const findingsArray = Object.values(findingsData || {});

  // Unique responsible options
  const uniqueResponsibles = findingsArray
    .flatMap((finding) =>
      Array.isArray(finding.responsible)
        ? finding.responsible
        : [finding.responsible],
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((responsible, index) => ({ id: responsible, label: responsible }));

  // Unique Risk Levels Options
  const uniqueLevels = findingsArray
    .map((finding) => finding.risk?.level || "unknown") // Handle missing level
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((level) => ({
      id: level.toLowerCase(), // Keep lowercase for filtering
      label: level.charAt(0).toUpperCase() + level.slice(1).toLowerCase(), // Format for display
    }));

  const handleLevelFilterChange = (newSelectedValues) => {
    setSelectedLevels(newSelectedValues);
  };

  // Status Options
  const uniqueStatuses = findingsArray
    .map((finding) => finding.status)
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((status, index) => ({ id: status, label: status }));

  // Unique Risk Options
  const uniqueRisks = findingsArray
    .map((finding) => finding.risk?.risk_id || "No Risk ID") // Handle missing risk_id
    .filter((value, index, self) => value && self.indexOf(value) === index)
    .map((riskId) => ({ id: riskId, label: riskId }))
    .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label

  const handleResponsibleFilterChange = (newSelectedValues) => {
    setSelectedResponsibles(newSelectedValues);
  };

  const handleStatusFilterChange = (newSelectedValues) => {
    setSelectedStatuses(newSelectedValues);
  };

  const handleDateRangeFilterChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleRiskFilterChange = (newSelectedValues) => {
    setSelectedRisks(newSelectedValues);
  };

  const handleExportSelectedFindings = async () => {
    setLoading(true);
    await handleExport({
      selectedItems: selectedFindings,
      orgId,
      sessionToken,
      getKey: (item) => item.key,
      filePrefix: "Finding",
    });
    setLoading(false);
  };

  const filteredData = findingsArray.filter((item) => {
    const {
      responsible,
      accountable,
      consulted,
      informed,
      status,
      date: itemDate,
      risk,
      reference,
      reporter,
    } = item;
    const dueDate = new Date(itemDate);

    const formattedStartDate = dateRange[0];
    const formattedEndDate = dateRange[1];

    const searchQueryLower = searchQuery.toLowerCase();

    const filters = [
      selectedResponsibles.length === 0 ||
        (Array.isArray(responsible)
          ? responsible.some((r) => selectedResponsibles.includes(r))
          : selectedResponsibles.includes(responsible)),
      selectedStatuses.length === 0 || selectedStatuses.includes(status),
      selectedLevels.length === 0 ||
        selectedLevels.includes(risk?.level?.toLowerCase() || "unknown"),
      selectedRisks.length === 0 ||
        selectedRisks.includes(risk?.risk_id || "No Risk ID"),
      (!formattedStartDate && !formattedEndDate) ||
        (dueDate >= formattedStartDate && dueDate <= formattedEndDate),
      !searchQueryLower ||
        item.key?.toLowerCase().includes(searchQueryLower) ||
        item.description?.toLowerCase().includes(searchQueryLower) ||
        reference?.toLowerCase().includes(searchQueryLower) ||
        reporter?.toLowerCase().includes(searchQueryLower) ||
        (Array.isArray(responsible)
          ? responsible.some((r) =>
              r
                .toLowerCase()
                .normalize()
                .includes(searchQueryLower.normalize()),
            )
          : responsible
              ?.toLowerCase()
              .normalize()
              .includes(searchQueryLower.normalize())) ||
        status
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.qms_id
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.risk_id
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()) ||
        risk?.level
          ?.toLowerCase()
          .normalize()
          .includes(searchQueryLower.normalize()),
      !meModeActive ||
        [responsible, accountable, consulted, informed, reporter].some(
          (role) =>
            Array.isArray(role)
              ? role.includes(currentUser)
              : role === currentUser,
        ),
    ];

    return filters.every(Boolean);
  });

  const getLinkedData = (finding) => {
    const { linked_improvements = [], linked_audittasks = [] } = finding;

    const improvementsData = data.improvements || {}; // Default to empty object if null

    const improvements = linked_improvements
      .map((key) => {
        if (improvementsData[key]) {
          const improvement = improvementsData[key];
          return improvement;
        } else {
          console.warn(`No improvement found for key ${key}`);
          return undefined;
        }
      })
      .filter(Boolean); // Filter out any undefined values

    const auditTasks = linked_audittasks
      .map((key) => {
        if (data.audit_tasks && data.audit_tasks[key]) {
          const auditTask = data.audit_tasks[key];
          return auditTask;
        } else {
          console.warn(`No audit task found for key ${key}`);
          return undefined;
        }
      })
      .filter(Boolean); // Filter out any undefined values

    return { linked_improvements: improvements, linked_auditTasks: auditTasks };
  };

  const handleRowClick = (row) => {
    const linkedData = getLinkedData(row.original);

    dispatch(setLinkedData(linkedData));
    setSelectedFindingData(row.original);
    dispatch(
      showModal({
        name: "findings_modal",
        data: row.original,
      }),
    );
  };

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text?.substring(0, maxLength)}
        <span className={styles.readMore}>... read more</span>
      </>
    );
  };

  console.log(selectedFindings, "selektovani");

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedFindings(selectedRows);
  };

  const handleOpenNewFindingModal = () => {
    dispatch(
      showModal({
        name: "new_improvement_modal",
        data: selectedFindings,
      }),
    );
  };

  const columns = [
    {
      accessorKey: "select",
      header: ({ table }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Stop click event propagation
            table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected());
          }}
        >
          {table.getIsAllPageRowsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      size: 50,
      cell: ({ row }) => (
        <div
          className={styles.customCheckboxContainer}
          onClick={(e) => {
            e.stopPropagation(); // Prevent row click
            row.toggleSelected();
          }}
        >
          {row.getIsSelected() ? (
            <div className={styles.checked} />
          ) : (
            <div className={styles.unchecked} />
          )}
        </div>
      ),
      enableSorting: false,
    },
    {
      accessorKey: "key",
      header: "Key",
      size: 90,
      cell: (props) => <div>{props.getValue()}</div>,
      sortingFn: (rowA, rowB, columnId) => {
        const valueA = rowA.getValue(columnId);
        const valueB = rowB.getValue(columnId);

        // Extract numeric portion from keys
        const numA = parseInt(valueA.split("-")[1], 10);
        const numB = parseInt(valueB.split("-")[1], 10);

        return numA - numB;
      },
    },
    {
      accessorKey: "reference",
      header: "Reference",
      size: 200,
      cell: (props) => <div>{props.getValue()}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "risk.risk_id",
      header: "Risk",
      size: 100,
      cell: (props) => {
        const riskId = props.row.original?.risk?.risk_id;
        return <div>{riskId || "No Risk ID"}</div>; // Fallback if risk_id is undefined
      },
      enableSorting: false,
    },
    {
      accessorKey: "risk.level",
      header: "Level",
      size: 100,
      cell: (props) => {
        const level = props.row.original?.risk?.level || "unknown"; // Fallback if level is undefined
        const formattedLevel =
          level.charAt(0).toUpperCase() + level.slice(1).toLowerCase(); // Format to uppercase first letter
        return <div>{formattedLevel}</div>;
      },
      enableSorting: false,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
      enableSorting: false,
    },
    {
      accessorKey: "reporter",
      header: "Reporter",
      size: 170,
      cell: (props) => {
        return props.row.original.reporter ? (
          <div>{props.row.original.reporter}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 170,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "In Review":
            statusClassName = styles.closedStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 130,
      cell: (props) => <div>{formatDate(props.getValue())}</div>,
      sortingFn: "datetime",
    },
  ];

  const risksArray = Object.values(risksData || {});

  // Pie chart URL for risk levels
  const pieChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "pie",
        data: {
          datasets: [
            {
              data: [
                filteredData.filter((r) => r.risk?.level === "low").length,
                filteredData.filter((r) => r.risk?.level === "medium").length,
                filteredData.filter((r) => r.risk?.level === "high").length,
              ],
              backgroundColor: ["#007940", "#FF7427", "#C60C30"],
            },
          ],
          labels: ["Low", "Medium", "High"],
        },
        options: {
          title: {
            display: true,
            position: "top",
            fontSize: 20,
            text: "Risk levels",
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        },
      }),
    );

  // Generate the bar chart URL with the QMS chapters counts
  const barChartUrl =
    "https://chart.entropical.io/chart?c=" +
    encodeURIComponent(
      JSON.stringify({
        type: "bar",
        data: {
          labels: ["A", "B", "C", "D", "E", "F", "G", "H"], // Main QMS letters
          datasets: [
            {
              backgroundColor: "#B4E1FA",
              data: ["A", "B", "C", "D", "E", "F", "G", "H"].map((letter) => {
                return filteredData.filter(
                  (item) =>
                    item.risk &&
                    item.risk.qms_id &&
                    item.risk.qms_id.trim().startsWith(letter),
                ).length;
              }),
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: "QMS Chapters",
            fontSize: 20,
          },
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              color: "#222229",
              font: {
                weight: "normal",
                size: 14,
              },
            },
          },
        },
      }),
    );

  return (
    <MainLayout>
      <div className={styles.column}>
        <FindingsModal
          findingData={selectedFindingData}
          isLoading={isFindingsModalLoading}
        />
        {isFindingsDataLoading && (
          <PageLoader text="Loading, it will be ready shortly" />
        )}
        {!isFindingsDataLoading && (
          <>
            <Breadcrumbs data={currentModule} />
            <Separator />
            <div className={styles.findingsContainer}>
              <div className={styles.rowWrapper}>
                <div className={styles.filtersWrapper}>
                  <Button
                    text={loading ? "Exporting..." : "Export findings"}
                    className={styles.button}
                    onClick={handleExportSelectedFindings}
                    disabled={selectedFindings.length === 0 || loading}
                  />
                  <Button
                    text="Create New Improvement Project"
                    className={styles.button}
                    onClick={handleOpenNewFindingModal}
                    disabled={selectedFindings.length === 0}
                  />
                  <MultiSelect
                    label={
                      selectedResponsibles.length > 0
                        ? selectedResponsibles.join(", ")
                        : "Responsible"
                    }
                    options={uniqueResponsibles}
                    onChangeSelectedValues={handleResponsibleFilterChange}
                    selectedValues={selectedResponsibles}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedStatuses.length > 0
                        ? selectedStatuses.join(", ")
                        : "Status"
                    }
                    options={uniqueStatuses}
                    onChangeSelectedValues={handleStatusFilterChange}
                    selectedValues={selectedStatuses}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedLevels.length > 0
                        ? selectedLevels
                            .map(
                              (level) =>
                                uniqueLevels.find(
                                  (option) => option.id === level,
                                )?.label || level,
                            )
                            .join(", ")
                        : "Levels"
                    }
                    options={uniqueLevels}
                    onChangeSelectedValues={handleLevelFilterChange}
                    selectedValues={selectedLevels}
                    disabled={!findingsArray.length}
                  />
                  <MultiSelect
                    label={
                      selectedRisks.length > 0
                        ? selectedRisks.join(", ")
                        : "Risk"
                    }
                    options={uniqueRisks}
                    onChangeSelectedValues={handleRiskFilterChange}
                    selectedValues={selectedRisks}
                    disabled={!findingsArray.length}
                  />
                  <DateRangeFilter
                    handleDateRangeChange={handleDateRangeFilterChange}
                    dateRange={dateRange}
                    isClearable
                  />
                </div>
                <Input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={styles.input}
                  icon={SearchIcon}
                />
              </div>
              <Table
                columns={columns}
                data={filteredData}
                onRowClick={handleRowClick}
                onSelectedRowsChange={handleSelectedRowsChange}
                rowHover
                initialSorting={[{ id: "date", desc: true }]}
              />
              {!filteredData.length && (
                <div className={styles.noData}>
                  Sorry, there is no data to display.
                </div>
              )}
              <Separator className={styles.separator} />
              <div className={styles.chartWrapper}>
                {/* Display the charts */}
                <img
                  src={pieChartUrl}
                  alt="Risk Levels Pie Chart"
                  className={styles.chartImage}
                />
                <img
                  src={barChartUrl}
                  alt="QMS Chapters Bar Chart"
                  className={styles.chartImage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </MainLayout>
  );
};

export default Findings;
