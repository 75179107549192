import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./FindingsModal.module.scss";
import classNames from "classnames";
import Loader from "../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import Separator from "../components/Separator/Separator";
import { formatDate } from "../utils/helpers";
import SecondaryButton from "../components/Button/SecondaryButton";
import { useNavigate, useParams } from "react-router-dom";
import { hideModal } from "../redux/modalStore";
import Table from "../components/Table/Table";
import { ReactComponent as EditIcon } from "../icons/edit.svg";
import Button from "../components/Button/Button";
import { Field, Form, Formik } from "formik";
import Input from "../components/Input/Input";
import Select from "../components/Select/Select";
import Textarea from "../components/Textarea/Textarea";
import axiosInstance from "../utils/utils";
import { getOrganizationData } from "../actions/organization";
import Pill from "../components/Pill/Pill";
import * as Yup from "yup";
import ModalSideBar from "../components/ModalSideBar/ModalSideBar";
import ConfirmationPopup from "./ConfirmationPopup";
import ReactMarkdown from "react-markdown";

const FindingsModal = ({ findingData, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const sessionToken = localStorage.getItem("session_token");

  const { linkedData, risksData } = useSelector((state) => state.organization);
  const openedModal = useSelector((state) => state.modal.openedModal);

  const [editField, setEditField] = useState(null);
  const [editableData, setEditableData] = useState(findingData || {});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [updates, setUpdates] = useState(openedModal?.data?.updates || []);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isRiskUpdating, setRiskUpdating] = useState(false);

  console.log(findingData, "fin data");

  useEffect(() => {
    setEditableData(findingData || {});
    setUnsavedChanges({});
    setEditField(null);
    setLoading(false);
    setUpdates(openedModal?.data?.updates || []);
  }, [findingData, openedModal?.data]);

  const handleOnClose = (dirty, resetForm) => {
    if (dirty) {
      setShowConfirmationPopup(true);
    } else {
      // Move modal hiding first to avoid unmounting issues
      dispatch(hideModal());

      // Ensure resetForm is defined before calling it
      if (resetForm && typeof resetForm === "function") {
        resetForm();
      }

      // Reset other states after closing the modal
      setEditableData(findingData || {});
      setEditField(null);
    }
  };

  const handleConfirmClose = (resetForm) => {
    setShowConfirmationPopup(false);
    dispatch(hideModal());
    resetForm();
  };

  const handleCancel = () => {
    setShowConfirmationPopup(false);
  };

  const handleNavigationClick = (path) => {
    navigate(`/${orgId}/${path}`);
    handleOnClose();
  };

  const handleFieldChange = (field, value) => {
    const newValue = value === "" ? (field === "responsible" ? "" : []) : value;
    setUnsavedChanges((prevChanges) => ({
      ...prevChanges,
      [field]: newValue,
    }));
    setEditableData((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleSave = async (values, resetForm) => {
    const sanitizeData = (data) => {
      return Object.keys(data).reduce((acc, key) => {
        if (Array.isArray(data[key])) {
          // If the array is empty or contains only empty strings, send it as an empty array
          acc[key] =
            data[key].filter((item) =>
              typeof item === "string" ? item.trim() !== "" : item,
            ) || [];
        } else if (typeof data[key] === "string") {
          // Allow empty strings to be sent
          acc[key] = data[key].trim();
        } else {
          // For other types, retain as is or set to null if explicitly undefined
          acc[key] = data[key] ?? "";
        }
        return acc;
      }, {});
    };

    const sanitizedValues = sanitizeData(values);
    const sanitizedOriginalData = sanitizeData(findingData);

    const changedFields = {};
    for (const key in sanitizedValues) {
      if (key === "responsible") {
        if (sanitizedValues[key] !== sanitizedOriginalData[key]) {
          changedFields[key] = sanitizedValues[key];
        }
      } else if (
        typeof sanitizedValues[key] === "object" &&
        sanitizedValues[key] !== null &&
        !Array.isArray(sanitizedValues[key])
      ) {
        // For objects, compare their JSON string representations
        if (
          JSON.stringify(sanitizedValues[key]) !==
          JSON.stringify(sanitizedOriginalData[key])
        ) {
          changedFields[key] = sanitizedValues[key];
        }
      } else if (Array.isArray(sanitizedValues[key])) {
        // Ensure both values are arrays before comparing with sort
        const sortedSanitizedValues = [...sanitizedValues[key]].sort(); // Create a shallow copy before sorting
        const sortedOriginalData = Array.isArray(sanitizedOriginalData[key])
          ? [...sanitizedOriginalData[key]].sort() // Create a shallow copy before sorting
          : [];
        if (
          JSON.stringify(sortedSanitizedValues) !==
          JSON.stringify(sortedOriginalData)
        ) {
          changedFields[key] = sanitizedValues[key];
        }
      } else {
        // For primitive values (string, number, etc.)
        if (sanitizedValues[key] !== sanitizedOriginalData[key]) {
          changedFields[key] = sanitizedValues[key];
        }
      }
    }
    if (Object.keys(changedFields).length === 0) {
      return;
    }

    const payload = {
      id: findingData?.id,
      ...changedFields,
    };
    setLoading(true);
    setRiskUpdating(true);

    try {
      const response = await axiosInstance.patch(
        `/nocodb/findings?org_id=${orgId}`,
        [payload],
        {
          headers: {
            "Content-Type": "application/json",
            "session-token": sessionToken,
          },
        },
      );

      // Update the local state with the saved changes
      setEditableData((prevData) => ({
        ...prevData,
        ...changedFields,
      }));

      setUnsavedChanges({});
      setEditField(null);

      // Fetch the updated organization data
      await dispatch(
        getOrganizationData(
          orgId,
          sessionToken,
          navigate,
          false,
          true,
          true,
          true,
          false,
          false,
        ),
      );

      // Reset the form after data is fetched successfully, with a slight delay
      setTimeout(() => {
        resetForm(); // Ensure form resets after everything is done
      }, 0);
    } catch (error) {
      console.error("There was a problem with the save operation:", error);
    } finally {
      setLoading(false);
      setRiskUpdating(false);
      handleOnClose(); // Close the modal after saving and fetching data
    }
  };

  const safeLinkedData = Array.isArray(linkedData) ? linkedData : [];

  const columns = [
    {
      accessorKey: "key",
      header: "Key",
      size: 90,
      cell: (props) => <div>{props.getValue()}</div>,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 135,
      cell: (props) => <div>{simpleTruncateText(props.getValue(), 40)}</div>,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 350,
      cell: (props) => <div>{truncateText(props.getValue(), 80)}</div>,
    },
    {
      accessorKey: "responsible",
      header: "Responsible",
      size: 200,
      cell: (props) => {
        return props.row.original.responsible ? (
          <div>{props.row.original.responsible}</div>
        ) : (
          <div>Not defined</div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 120,
      minSize: 90,
      cell: (props) => {
        const status = props.getValue();
        let statusClassName = "";

        switch (status) {
          case "Open":
            statusClassName = styles.openStatus;
            break;
          case "In Progress":
            statusClassName = styles.inProgressStatus;
            break;
          case "Closed":
            statusClassName = styles.closedStatus;
            break;
          case "Done":
            statusClassName = styles.resolvedStatus;
            break;
          default:
            break;
        }

        return (
          <div className={classNames(styles.statusCell, statusClassName)}>
            {status}
          </div>
        );
      },
    },
  ];

  const truncateText = (text, maxLength) => {
    if (!text) {
      // If text is null, undefined, or an empty string
      return "There is no description provided";
    }

    if (text.length <= maxLength) {
      return text;
    }

    return (
      <>
        {text.substring(0, maxLength)}
        <span className={styles.readMore}>...</span>
      </>
    );
  };

  const simpleTruncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return (
      <>
        {text.substring(0, maxLength)}
        <span>...</span>
      </>
    );
  };

  let riskColor;
  switch (findingData?.risk?.level) {
    case "high":
      riskColor = styles.highLevel;
      break;
    case "medium":
      riskColor = styles.mediumLevel;
      break;
    case "low":
      riskColor = styles.lowLevel;
      break;
    default:
      riskColor = "";
  }

  const statusOptions = [
    {
      id: 1,
      label: "Open",
    },
    {
      id: 2,
      label: "In Progress",
    },
    {
      id: 3,
      label: "In Review",
    },
    {
      id: 4,
      label: "Done",
    },
  ];

  // Helper function to get the label from statusOptions based on the value
  const getStatusLabel = (statusValue) => {
    const statusOption = statusOptions.find(
      (option) => option.label === statusValue,
    );
    return statusOption ? statusOption.label : statusValue;
  };

  console.log(isSidebarOpen, "is sidebar open");

  const renderEmails = (emails, editField, fieldName, setEditField) => {
    if (editField === fieldName) {
      return (
        <Field
          name={fieldName}
          component={Pill}
          placeholder="Enter emails"
          limit={5}
        />
      );
    }

    if (Array.isArray(emails)) {
      return emails.map((email) => (
        <div key={email} className={styles.items}>
          {email}
        </div>
      ));
    } else if (typeof emails === "string") {
      return <div className={styles.items}>{emails}</div>;
    }

    return null;
  };

  const renderFormattedSummary = (content) => {
    if (!content) return <div>No summary provided.</div>;

    return <ReactMarkdown>{content}</ReactMarkdown>;
  };

  const validationSchema = Yup.object().shape({
    responsible: Yup.string().test(
      "is-valid",
      "Invalid email or name",
      (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const nameRegex = /^[a-zA-Z .]+$/; // Allows letters, spaces, and dots
        return emailRegex.test(value) || nameRegex.test(value);
      },
    ),
  });

  return (
    <>
      <Formik
        initialValues={{
          ...editableData,
          riskLabel: editableData.risk
            ? `${editableData.risk.risk_id} - ${editableData.risk.description}`
            : "",
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          // Find the risk number based on the selected label
          const selectedRisk = Object.values(risksData).find(
            (risk) =>
              `${risk.risk_id} - ${risk.description}` === values.riskLabel,
          );

          // Modify the values object to only include the risk_num as a string
          const modifiedValues = {
            ...values,
            risk: selectedRisk ? String(selectedRisk.risk_num) : "", // Store risk_num as a string
          };

          // Remove the riskLabel before sending to handleSave
          delete modifiedValues.riskLabel;

          handleSave(modifiedValues, resetForm); // Pass the modified values to handleSave
        }}
      >
        {({
          values,
          setFieldValue,
          dirty,
          resetForm,
          submitForm,
          isSubmitting,
        }) => {
          return (
            <>
              <Modal
                key={findingData?.id}
                name="findings_modal"
                title={findingData?.key}
                onClose={() => handleOnClose(dirty, resetForm)}
                width="95vw"
                height="95vh"
                headerAction={
                  <Button
                    text={loading ? "Saving..." : "Save Changes"}
                    onClick={submitForm} // Trigger Formik's submit
                    className={styles.submitButton}
                    disabled={!dirty || isSubmitting} // Disable if no changes or submitting
                  />
                }
              >
                <div className={styles.modalContentContainer}>
                  <div className={styles.modalContent}>
                    <Form className={styles.content}>
                      <div className={styles.header}>
                        <div className={styles.leftSide}>
                          <div className={styles.row}>
                            <div className={styles.title}>Reporter:</div>
                            <div className={styles.bold}>
                              {findingData?.reporter}
                            </div>
                          </div>
                          <div className={styles.row}>
                            <div className={styles.title}>Date:</div>
                            <div className={styles.bold}>
                              {formatDate(findingData?.date)}
                            </div>
                          </div>
                        </div>
                        <div className={styles.rightSide}>
                          <div className={styles.row}>
                            <div className={styles.title}>Status:</div>
                            <div className={styles.statusContainer}>
                              {editField === "status" ? (
                                <Field
                                  name="status"
                                  component={Select}
                                  options={statusOptions}
                                  value={values.status || editableData?.status}
                                  onChange={(e) => {
                                    const selectedOption = e.target.value;
                                    setFieldValue("status", selectedOption);
                                    handleFieldChange("status", selectedOption);
                                    setEditField(null);
                                  }}
                                >
                                  {getStatusLabel(
                                    values.status || editableData?.status,
                                  )}
                                </Field>
                              ) : (
                                <>
                                  <div
                                    className={classNames(
                                      styles.statusCell,
                                      values.status === "In Progress"
                                        ? styles.inProgressStatus
                                        : values.status === "In Review"
                                          ? styles.inReviewStatus
                                          : values.status === "Done"
                                            ? styles.resolvedStatus
                                            : styles.openStatus,
                                    )}
                                  >
                                    {values.status || editableData?.status}
                                  </div>
                                  <EditIcon
                                    className={styles.editIcon}
                                    onClick={() => setEditField("status")}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          {!isSidebarOpen && (
                            <SecondaryButton
                              text="See Updates"
                              onClick={() => setSidebarOpen(true)}
                              className={styles.toggleSidebarButton}
                              type="Button"
                            />
                          )}
                        </div>
                      </div>
                      <Separator />
                      <>
                        <div className={styles.row}>
                          <div className={styles.title}>Risk Level:</div>
                          {isRiskUpdating ? (
                            <div className={styles.bold}>Updating risk...</div>
                          ) : (
                            <div
                              className={classNames(
                                styles.riskLevel,
                                editableData?.risk?.level && riskColor,
                              )}
                            >
                              {editableData?.risk?.level || "Undefined"}
                            </div>
                          )}
                        </div>

                        <div className={styles.row}>
                          <div className={styles.title}>Risk:</div>
                          {editField === "risk" ? (
                            <>
                              <Field
                                name="riskLabel"
                                component={Select}
                                className={styles.select}
                                text="Select risk"
                                options={Object.values(risksData)
                                  .sort((a, b) =>
                                    a.risk_id.localeCompare(b.risk_id),
                                  )
                                  .map((risk) => ({
                                    value: `${risk.risk_id} - ${risk.description}`,
                                    label: `${risk.risk_id} - ${risk.description}`,
                                  }))}
                                onChange={(e) => {
                                  setFieldValue("riskLabel", e.target.value); // Store the selected label
                                  setEditField(null);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <div className={styles.bold}>
                                {isRiskUpdating
                                  ? "Updating risk..."
                                  : findingData?.risk
                                    ? `${editableData?.risk?.risk_id} - ${editableData?.risk?.description}`
                                    : "Undefined"}
                              </div>
                              <EditIcon
                                className={styles.editIcon}
                                onClick={() => setEditField("risk")}
                              />
                            </>
                          )}
                        </div>
                      </>
                      <div className={styles.tableContainer}>
                        <table className={styles.teamRolesTable}>
                          <tbody>
                            {/* Responsible */}
                            <tr>
                              <td>Responsible</td>
                              <td>
                                {editField === "responsible" ? (
                                  <Field
                                    name="responsible"
                                    component={Input}
                                    placeholder="Enter responsible email"
                                  />
                                ) : (
                                  <div>
                                    {Array.isArray(values.responsible)
                                      ? values.responsible.join(", ")
                                      : values.responsible || ""}
                                  </div>
                                )}
                              </td>
                              <td>
                                <EditIcon
                                  className={styles.editIcon}
                                  onClick={() => setEditField("responsible")}
                                />
                              </td>
                            </tr>
                            {/* Accountable */}
                            <tr>
                              <td>Accountable</td>
                              <td>
                                {editField === "accountable" ? (
                                  <Field
                                    name="accountable"
                                    component={Pill}
                                    placeholder="Enter accountable emails"
                                    limit={5}
                                  />
                                ) : (
                                  (values.accountable || []).map(
                                    (email, index) => (
                                      <div key={index}>{email}</div>
                                    ),
                                  ) || "Not defined"
                                )}
                              </td>
                              <td>
                                <EditIcon
                                  className={styles.editIcon}
                                  onClick={() => setEditField("accountable")}
                                />
                              </td>
                            </tr>
                            {/* Consulted */}
                            <tr>
                              <td>Consulted</td>
                              <td>
                                {editField === "consulted" ? (
                                  <Field
                                    name="consulted"
                                    component={Pill}
                                    placeholder="Enter consulted emails"
                                    limit={5}
                                  />
                                ) : (
                                  (values.consulted || []).map(
                                    (email, index) => (
                                      <div key={index}>{email}</div>
                                    ),
                                  ) || "Not defined"
                                )}
                              </td>
                              <td>
                                <EditIcon
                                  className={styles.editIcon}
                                  onClick={() => setEditField("consulted")}
                                />
                              </td>
                            </tr>
                            {/* Informed */}
                            <tr>
                              <td>Informed</td>
                              <td>
                                {editField === "informed" ? (
                                  <Field
                                    name="informed"
                                    component={Pill}
                                    placeholder="Enter informed emails"
                                    limit={5}
                                  />
                                ) : (
                                  (values.informed || []).map(
                                    (email, index) => (
                                      <div key={index}>{email}</div>
                                    ),
                                  ) || "Not defined"
                                )}
                              </td>
                              <td>
                                <EditIcon
                                  className={styles.editIcon}
                                  onClick={() => setEditField("informed")}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <Separator />
                      {/*Summary*/}
                      {findingData?.status === "Done" &&
                        findingData?.summary && (
                          <>
                            <div className={styles.title}>Summary:</div>
                            <div>
                              {renderFormattedSummary(findingData?.summary)}
                            </div>
                            <Separator />
                          </>
                        )}
                      {/*Reference*/}
                      <div className={styles.row}>
                        <div className={styles.title}>Reference:</div>
                        {editField === "reference" ? (
                          <Field
                            name="reference"
                            component={Input}
                            className={styles.bold}
                          />
                        ) : (
                          <>
                            <div className={styles.bold}>
                              {values.reference || editableData?.reference
                                ? values.reference || editableData?.reference
                                : "Not Defined"}
                            </div>
                            <EditIcon
                              className={styles.editIcon}
                              onClick={() => setEditField("reference")}
                            />
                          </>
                        )}
                      </div>

                      <div className={styles.title}>Description:</div>
                      {editField === "description" ? (
                        <Field name="description" component={Textarea} />
                      ) : (
                        <div className={styles.description}>
                          <div>
                            {values.description || editableData?.description
                              ? values.description || editableData?.description
                              : "Not Defined"}
                          </div>
                          <EditIcon
                            className={styles.editIcon}
                            onClick={() => setEditField("description")}
                          />
                        </div>
                      )}
                      <Separator />
                      {linkedData?.linked_improvements?.length > 0 && (
                        <>
                          <div className={styles.title}>
                            Linked Improvements
                          </div>
                          <div className={styles.tableContainer}>
                            <Table
                              columns={columns}
                              data={linkedData.linked_improvements}
                            />
                          </div>
                          <SecondaryButton
                            text="View all improvements..."
                            className={styles.secondaryButton}
                            onClick={() =>
                              handleNavigationClick("improvement-projects")
                            }
                          />
                        </>
                      )}
                      {linkedData?.linked_auditTasks?.length > 0 && (
                        <>
                          <Separator />
                          <div className={styles.title}>Linked Audit Tasks</div>
                          <div className={styles.tableContainer}>
                            <Table
                              columns={columns}
                              data={linkedData?.linked_auditTasks}
                            />
                          </div>
                          <SecondaryButton
                            text="View all audit tasks..."
                            className={styles.secondaryButton}
                            onClick={() => handleNavigationClick("audits")}
                          />
                        </>
                      )}
                      {findingData?.attachments.length > 0 && (
                        <>
                          <div className={styles.title}>Attachments:</div>
                          <div className={styles.attachments}>
                            {findingData?.attachments.map((attachment) => (
                              <a
                                key={attachment}
                                href={attachment.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className={styles.attachmentItem}
                              >
                                {attachment.filename}
                              </a>
                            ))}
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                  <ModalSideBar
                    isOpen={isSidebarOpen}
                    onClose={() => setSidebarOpen(false)}
                    updates={updates}
                    setUpdates={setUpdates}
                    findingId={openedModal?.data?.id}
                  />
                </div>
              </Modal>
              {showConfirmationPopup && (
                <ConfirmationPopup
                  onConfirmClose={() => handleConfirmClose(resetForm)}
                  onCancel={handleCancel}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default FindingsModal;
