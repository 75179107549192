import Swal from "sweetalert2";
import axiosInstance from "./utils";

export function hasRealContent(htmlString) {
  // Check if the input is null or undefined
  if (!htmlString) {
    return false;
  }

  // Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Helper function to check if an element has real content
  function elementHasContent(element) {
    // Ignore empty text nodes and specific empty elements like <br>
    if (element.nodeType === Node.TEXT_NODE) {
      return element.nodeValue.trim().length > 0;
    }
    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.tagName === "BR") return false;
      // Check for non-empty text content or nested elements with content
      if (element.textContent.trim().length > 0) return true;
      // Recursively check child nodes
      for (let child of element.childNodes) {
        if (elementHasContent(child)) return true;
      }
    }
    return false;
  }

  // Check the body of the parsed HTML document
  return elementHasContent(doc.body);
}

// Format date to exclude hours
export function formatDate(dateString) {
  // Try to parse the date string into a Date object
  const date = new Date(dateString);

  // Check if the parsed date is valid
  if (!isNaN(date.getTime())) {
    //getTime() method to get the number representation of the date
    // Extract day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Return formatted date string without hours
    return `${day} ${month} ${year}`;
  } else {
    // If the date string is not a valid date, return the original string
    return dateString;
  }
}

// Helper function to truncate text to a specified word limit and add ellipsis
export const getTruncatedContent = (text, wordLimit) => {
  if (typeof text !== "string") {
    return "";
  }

  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
};

// Helper function to find category by using title
export const findCategory = (title, data) => {
  const item = data?.find((obj) => obj.title === title);
  return item ? item.category : "";
};

export function getColor(value, min, max) {
  if (min === max) {
    return "rgb(177, 252, 184)"; // Use green color when all counts are the same
  }

  const x_max = Math.max(max - min, 1); // Ensure x_max is not 0
  const x = value - min; // Value relative to min

  const hue = 120 - (x / x_max) * 120 + 6; // Adjusted hue calculation
  return `hsl(${hue}, 92%, 80%)`; // Use the new saturation and lightness values
}

// Function for getting count in Accordion/Graphs
export function generateCumulativeTimeSeries(findings, improvements) {
  function getPast12Months() {
    const months = [];
    const today = new Date();
    today.setMonth(today.getMonth() + 1);

    for (let i = 11; i >= 0; i--) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      months.push(date.toISOString().slice(0, 7)); // YYYY-MM format
    }
    return months;
  }

  const past12Months = getPast12Months();
  const riskGroups = {};
  const impacts = { low: 1, medium: 2, high: 3, null: 1 };

  // Helper function to get the latest status for a given month
  function getLatestStatus(finding, month) {
    let latestStatus = finding.status;
    for (const update of finding.updates) {
      const updateMonth = update.date.slice(0, 7);
      if (updateMonth <= month && update.body.includes("Status:")) {
        latestStatus = update.body.match(/Status: (\w+)/)[1];
      }
    }
    return latestStatus;
  }

  for (const finding of Object.values(findings)) {
    const findingMonth = finding.date.slice(0, 7);
    const impact = finding.risk ? impacts[finding.risk.level] : impacts.null;
    let groupKey = finding.risk ? finding.risk.risk_id : "Not Defined";

    if (!riskGroups[groupKey]) {
      riskGroups[groupKey] = past12Months.map((month) => ({
        month,
        count: 0,
        relatedFindings: [],
        relatedImprovements: [],
      }));
    }

    const startIndex = past12Months.findIndex(
      (month) => month === findingMonth,
    );

    // Accumulate the finding's impact up until the month it was resolved
    if (startIndex !== -1) {
      for (let i = startIndex; i < past12Months.length; i++) {
        const month = past12Months[i];
        const latestStatus = getLatestStatus(finding, month);

        // Only accumulate if the finding is unresolved
        if (latestStatus !== "Done") {
          riskGroups[groupKey][i].count += impact;
          riskGroups[groupKey][i].relatedFindings.push(finding);
        }
      }
    }
  }

  Object.values(improvements).forEach((improvement) => {
    const improvementMonth = improvement.date.slice(0, 7);
    const improvementIndex = past12Months.indexOf(improvementMonth);

    if (improvementIndex === -1) return;

    improvement.linked_findings.forEach((findingKey) => {
      const finding = findings[findingKey];
      if (finding) {
        const impact = finding.risk
          ? impacts[finding.risk.level]
          : impacts.null;
        let groupKey = finding.risk ? finding.risk.risk_id : "Not Defined";

        // Exclude resolved findings from future months
        if (riskGroups[groupKey]) {
          for (let i = improvementIndex; i < past12Months.length; i++) {
            const month = past12Months[i];
            const latestStatus = getLatestStatus(finding, month);

            if (latestStatus !== "Done") {
              // Apply the improvement impact only to the specific month it falls in
              riskGroups[groupKey][i].count = Math.max(
                0,
                riskGroups[groupKey][i].count - impact,
              );
              riskGroups[groupKey][i].relatedImprovements.push(improvement);
            }
          }
        }
      }
    });
  });

  return Object.keys(riskGroups)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc, key) => {
      acc[key] = riskGroups[key];
      return acc;
    }, {});
}

// Function for getting count in Risk Table
export function generateCumulativeRisk(findings, improvements) {
  // Helper function to generate past 12 months
  function getPast12Months() {
    const months = [];
    const today = new Date();
    for (let i = 11; i >= 0; i--) {
      const date = new Date(today.getFullYear(), today.getMonth() - i + 1, 1);
      months.push(date.toISOString().slice(0, 7)); // YYYY-MM format
    }
    return months;
  }

  const past12Months = getPast12Months();
  const riskGroups = {};

  const impacts = { low: 1, medium: 2, high: 3, null: 1 };

  // Sort findings by date
  const sortedFindings = Object.values(findings).sort(
    (a, b) => new Date(a.date) - new Date(b.date),
  );

  // Process findings
  for (const finding of sortedFindings) {
    const findingMonth = finding.date.slice(0, 7);
    const riskNum = finding.risk ? finding.risk.risk_num : null;
    const impact = finding.risk ? impacts[finding.risk.level] : impacts[null];

    if (!riskGroups[riskNum]) {
      riskGroups[riskNum] = past12Months.map((month) => ({ month, count: 0 }));
    }

    const startIndex = past12Months.findIndex((month) => month >= findingMonth);
    for (let i = startIndex; i < past12Months.length; i++) {
      riskGroups[riskNum][i].count += impact;
    }
  }

  // Process improvements
  Object.values(improvements).forEach((improvement) => {
    if (!improvement.date) return;
    if (improvement.status !== "Done") return; // Only deduct if improvement is done
    const improvementMonth = improvement.date.slice(0, 7);
    const improvementIndex = past12Months.findIndex(
      (month) => month === improvementMonth,
    );

    if (improvementIndex === -1) return; // Improvement is outside our 12-month window

    improvement.linked_findings.forEach((findingKey) => {
      const finding = findings[findingKey];
      if (finding) {
        const riskNum = finding.risk ? finding.risk.risk_num : null;
        const impact = finding.risk
          ? impacts[finding.risk.level]
          : impacts[null];
        if (riskGroups[riskNum]) {
          for (let i = improvementIndex; i < past12Months.length; i++) {
            riskGroups[riskNum][i].count = Math.max(
              0,
              riskGroups[riskNum][i].count - impact,
            );
          }
        }
      }
    });
  });

  return riskGroups;
}

// Helper function for past 12 months
export function getPast12Months() {
  const months = [];
  const today = new Date();
  for (let i = 11; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    months.push(date.toLocaleString("default", { month: "short" }));
  }
  return months;
}

// Helper function to strip HTML tags
export const stripHtmlTags = (html) => {
  if (!html) return "";
  return html.replace(/<\/?[^>]+(>|$)/g, ""); // Simple regex to remove HTML tags
};

export const handleExport = async ({
  selectedItems,
  orgId,
  sessionToken,
  getKey,
  filePrefix,
}) => {
  if (selectedItems.length === 0) {
    Swal.fire({
      toast: true,
      icon: "warning",
      title: `No ${filePrefix.toLowerCase()}s selected!`,
      text: `Please select at least one ${filePrefix.toLowerCase()} to export.`,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
    return false;
  }

  try {
    const keysToExport = selectedItems.map(getKey);
    const requestUrl = `/export/word?org_id=${orgId}`; // Construct the URL here

    const response = await axiosInstance.post(requestUrl, keysToExport, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        "session-token": sessionToken,
      },
    });

    const fileName = `${filePrefix}_${keysToExport.join("_")}.docx`; // Example: Audits_A-2429_A-2354.docx

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    Swal.fire({
      toast: true,
      icon: "success",
      title: `${filePrefix} export successful!`,
      text: `The ${filePrefix.toLowerCase()}s (${keysToExport.join(", ")}) have been exported.`,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    return true;
  } catch (error) {
    console.error(`Error exporting ${filePrefix.toLowerCase()}s:`, error);

    Swal.fire({
      toast: true,
      icon: "error",
      title: `${filePrefix} export failed!`,
      text: `An error occurred while exporting ${filePrefix.toLowerCase()}s. Please try again.`,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    return false;
  }
};
